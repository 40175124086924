import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import * as XLSX from 'xlsx';
import './styles/Rack.css';

ChartJS.register(...registerables);

const Rack = ({ rack, currentPlant, JobResponse }) => {
  const [csvData, setCsvData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const timestampValues = [];
  const temperatureValues = [];
  const humidityValues = [];
  const ecValues = [];
  const phValues = [];
  const lightValues = [];

  useEffect(() => {
    const fetchCsvData = async (rackNumber) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/csv-path/${rackNumber}`);
        setCsvData(response.data);
      } catch (error) {
        console.error('Error fetching CSV data', error);
        setErrorMessage(error.response.data);
      }
    };

    fetchCsvData(rack);
  }, [rack]);

  csvData.forEach(item => {
    const timestamp = new Date(item.timestamp);

    if (item['Temperature']) {
      temperatureValues.push({ timestamp, value: parseFloat(item['Temperature']) });
    }
    if (item['Humidity']) {
      humidityValues.push({ timestamp, value: parseFloat(item['Humidity']) });
    }
    if (item['EC Value']) {
      ecValues.push({ timestamp, value: parseFloat(item['EC Value']) });
    }
    if (item['PH Value']) {
      phValues.push({ timestamp, value: parseFloat(item['PH Value']) });
    }
    if (item['Light']) {
      lightValues.push({ timestamp, value: parseFloat(item['Light']) });
    }

    timestampValues.push(timestamp);
  });

  const createChartData = (category, values, borderColor) => ({
    labels: timestampValues.map(point => point.toLocaleString()),
    datasets: [
      {
        label: category,
        data: values.map(point => point.value),
        borderColor: `${borderColor}, 1`,
        backgroundColor: `${borderColor}, 0.2)`,
      }
    ]
  });

  const calculateWeeklyData = (values) => {
    if (values.length === 0) return [];

    const weeks = [];
    let currentWeek = [];
    let weekStartDate = values[0].timestamp;

    values.forEach(point => {
      const date = new Date(point.timestamp);
      const diffInDays = (date - weekStartDate) / (1000 * 60 * 60 * 24);

      if (diffInDays >= 7) {
        weeks.push(currentWeek);
        currentWeek = [];
        weekStartDate = date;
      }

      currentWeek.push(point.value);
    });

    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    return weeks.map((week, index) => {
      if (week.length === 0) {
        return {
          week: `Week ${index + 1}`,
          min: 'No data',
          max: 'No data',
          avg: 'No data'
        };
      }
      const min = Math.min(...week);
      const max = Math.max(...week);
      const avg = (week.reduce((sum, val) => sum + val, 0) / week.length).toFixed(2);
      return {
        week: `Week ${index + 1}`,
        min,
        max,
        avg
      };
    });
  };

  const temperatureTable = calculateWeeklyData(temperatureValues);
  const humidityTable = calculateWeeklyData(humidityValues);
  const ecTable = calculateWeeklyData(ecValues);
  const phTable = calculateWeeklyData(phValues);
  const lightTable = calculateWeeklyData(lightValues);

  const renderTable = (data) => (
    <div className='table'>
      <table>
        <thead>
          <tr>
            <th>Week</th>
            <th>Min</th>
            <th>Max</th>
            <th>Avg</th>
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row.week}>
              <td>{row.week}</td>
              <td>{row.min}</td>
              <td>{row.max}</td>
              <td>{row.avg}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    
    // Add chart data sheet
    const wsChartData = [['Timestamp', 'Temperature', 'Humidity', 'EC Value', 'PH Value', 'Light']];
    csvData.forEach(item => {
      const row = [
        item.timestamp,
        item['Temperature'],
        item['Humidity'],
        item['EC Value'],
        item['PH Value'],
        item['Light']
      ];
      wsChartData.push(row);
    });
    const wsChart = XLSX.utils.aoa_to_sheet(wsChartData);
    XLSX.utils.book_append_sheet(wb, wsChart, 'Chart Data');

    // Add table data sheets
    const addTableSheet = (tableData, sheetName) => {
      const wsTableData = [['Week', 'Min', 'Max', 'Avg']];
      tableData.forEach(row => {
        wsTableData.push([row.week, row.min, row.max, row.avg]);
      });
      const wsTable = XLSX.utils.aoa_to_sheet(wsTableData);
      XLSX.utils.book_append_sheet(wb, wsTable, sheetName);
    };

    addTableSheet(temperatureTable, 'Temperature Table');
    addTableSheet(humidityTable, 'Humidity Table');
    addTableSheet(ecTable, 'EC Value Table');
    addTableSheet(phTable, 'PH Value Table');
    addTableSheet(lightTable, 'Light Table');
  
    // Add AI Sheet if JobResponse.age exists
    if (JobResponse && JobResponse.age) {
      const wsAIData = [['Timestamp', 'Actual Crop Age', 'AI Predicted Crop Age']];
      
      // Get the current date and time
      const now = new Date();
      const formattedTimestamp = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
      
      const actualCropAgeInDays = Math.floor((now.getTime() - new Date(currentPlant.transplantDate).getTime()) / (1000 * 60 * 60 * 24)); // Calculating the actual crop age in days
      const actualCropAgeInWeeks = Math.floor(actualCropAgeInDays / 7); // Converting days to weeks
      const predictedCropAge = JobResponse.age;
  
      wsAIData.push([formattedTimestamp, `Week${actualCropAgeInWeeks}`, predictedCropAge]);
  
      const wsAI = XLSX.utils.aoa_to_sheet(wsAIData);
      XLSX.utils.book_append_sheet(wb, wsAI, 'AI Sheet');
    }
  
    const transplantDate = new Date(currentPlant.transplantDate);
    const day = String(transplantDate.getDate()).padStart(2, '0'); // Ensure day is two digits
    const month = String(transplantDate.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
    const formattedDate1 = `${day}${month}`;
    const date = new Date();
    const formattedDate2 = `${date.getDate()}${String(date.getMonth() + 1).padStart(2, '0')}_${date.getFullYear()}`;
    XLSX.writeFile(wb, `rack_${rack}_${formattedDate1}_${formattedDate2}.xlsx`);
  };
  
  
  

  return (
   <>{errorMessage && (
      <div className="error-message">{errorMessage}</div>
    )}
    {!errorMessage && <div className='chart-container'>
      
      <h1>Sensor Data for Rack {rack}</h1>
      <button className='export-button' onClick={exportToExcel}>Export to Excel</button>
      <div className='chart'>
        <h2>Temperature</h2>
        <Line data={createChartData('Temperature', temperatureValues, 'rgba(245, 88, 39')} />
        {renderTable(temperatureTable)}
      </div>
      <div className='chart'>
        <h2>Humidity</h2>
        <Line data={createChartData('Humidity', humidityValues, 'rgba(75, 192, 192')} />
        {renderTable(humidityTable)}
      </div>
      <div className='chart'>
        <h2>EC Value</h2>
        <Line data={createChartData('EC Value', ecValues, 'rgba(94, 104, 181')} />
        {renderTable(ecTable)}
      </div>
      <div className='chart'>
      <h2>PH Value</h2>
        <Line data={createChartData('PH Value', phValues, 'rgba(94, 104, 181')} />
        {renderTable(phTable)}
      </div>
      <div className='chart'>
      <h2>Light</h2>
        <Line data={createChartData('Light', lightValues, 'rgba(94, 104, 181')} />
        {renderTable(lightTable)}
      </div>
    </div>}</>);
};

export default Rack;
